<template>
    <main
      class="
        transform-gpu
        transition-all
        duration-1000
        ease-out
        items-stretch
        justify-center
        h-screen
        w-full
        max-w-100vw
        overflow-hidden
        flex flex-col flex-1
        bg-darkblue
        text-white
      "
    >
      <div
        class="
          flex flex-col
          items-stretch
          text-left
          pr-8
          pl-12
          sm:pl-12
          md:pl-24
          xl:pl-32
          flex-1
          relative
        "
      >
        <transition
          enter-active-class="transform transition-all duration-500 ease-in"
          leave-active-class="transform transition-all duration-500 ease-in"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <HeaderComponent
            v-if="loadHeading"
            :darkTheme="false"
          ></HeaderComponent>
        </transition>
  
        <div
          class="
            xl:flex
            xl:items-start
            xl:justify-between
            max-h-90vh
            pt-14
            md:pt-24
            overflow-auto
          "
        >
          <div class="max-w-full xl:max-w-3/4">
            <transition
              enter-active-class="tranform transition-all duration-500 ease-in-out"
              leave-active-class="tranform transition-all duration-500 ease-in-out"
              enter-class="-ml-14 opacity-0"
              enter-to-class="opacity-100"
              leave-class="opacity-100"
              leave-to-class="-ml-14 opacity-0"
            >
              <div
                v-if="loadContent"
                class="flex flex-wrap items-center justify-start"
              >
                <h1
                  class="
                    font-concurso
                    uppercase
                    tracking-tighter
                    text-4xl
                    md:text-5xl
                  "
                >
                  Gaming Art
                </h1>
                <small
                  class="
                    uppercase
                    w-full
                    md:inline-flex
                    md:leading-1
                    md:ml-1
                    md:mb-1
                    md:self-end
                    text-lg
                    md:w-auto
                  "
                  >Discover the art of virtual photography through the immersive world of video games.</small
                >
              </div>
            </transition>
            <transition
              enter-active-class="transform transition-all duration-500 ease-in-out"
              leave-active-class="transform transition-all duration-500 ease-in-out"
              enter-class="-ml-14 opacity-0"
              enter-to-class="opacity-100"
              leave-class="opacity-100"
              leave-to-class="-ml-14 opacity-0"
            >
              <div v-if="loadContent" class="text-sm lg:text-base mt-6">
                <p class="mb-3">
                    Discover the art of virtual photography through the immersive world of video games. I love to celebrate the beauty and creativity that lies within gaming environments, transforming in-game moments into stunning works of art.
                </p>
                <p class="mb-3">
                    Dive into our extensive gallery of high-resolution screenshots from a variety of games. From breathtaking landscapes to intricate character portraits, this collection highlights the diverse beauty found in virtual worlds.
                </p>
                <p class="mb-3">
                    - Landscapes: Explore vast open worlds and detailed environments captured in all their glory. <br/>
                    - Characters: Get up close and personal with in-game characters, showcasing their design and emotions. <br/>
                    - Action Shots: Relive epic moments of gameplay, frozen in time through dynamic photography. <br/>
                </p>
                <br />
                <h5 class="mb-3 font-bold">What is Virtual Photography?</h5>
                <p class="mb-3">
                    Virtual photography is the practice of capturing artistic screenshots within video games. Much like traditional photography, it involves a keen eye for composition, lighting, and detail. However, instead of real-world scenes, virtual photographers explore digital landscapes, characters, and narratives to create visually compelling images.
                </p>
                <br />
                <h5 class="mb-3 font-bold">Gallery</h5>
                <p class="mb-3">
                    A Showcase of the limitless potential of virtual photography. 
                </p>

                <div class="mt-6 gap-4 flex flex-row flex-wrap">
                  <img
                    v-for="(image, index) in images"
                    :key="index"
                    :src="image.src"
                    :alt="image.alt"
                    @click="showLightBox(index)"
                    class="h-32 w-auto cursor-pointer"
                  />
                </div>

                <LightBox
                  ref="lightbox"
                  :images="images"
                  :initialIndex="currentIndex"
                />

              </div>
            </transition>
          </div>
        </div>
  
        <router-link
          to="/"
          class="
            fixed
            left-0
            top-0
            h-screen
            w-8
            md:w-12
            bg-darkerblue
            text-white
            hover:bg-black
            transition transition-bg
            flex
            items-center
            justify-center
          "
        >
          <div class="relative h-screen w-4vw flex items-center justify-center">
            <span
              class="
                absolute
                uppercase
                w-100vh
                h-6
                flex
                items-center
                justify-center
                transform
                rotate-90
                tracking-wide
              "
              >Home<i class="icon-arrow"></i
            ></span></div
        ></router-link>
      </div>
    </main>
  </template>
  
  <script>
  import HeaderComponent from "@/components/HeaderComponent.vue";
  import LightBox from '@/components/LightBox.vue';

  export default {
    name: "GamingArt",
    components: {
      HeaderComponent,
      LightBox
    },
    data() {
      return {
        loadContent: Boolean,
        loadHeading: Boolean,
        loadImage: Boolean,
        images: [
            { src: '/img/gaming-art-og/cyberpunk2077-city-scape-thumb.jpeg', alt: 'cyberpunk2077 city' },
            { src: '/img/gaming-art-og/cyberpunk2077-highway-thumb.jpeg', alt: 'cyberpunk2077 highway' },
            { src: '/img/gaming-art-og/cyberpunk2077-passagelights-thumb.jpeg', alt: 'cyberpunk2077 club lights' },
            { src: '/img/gaming-art-og/rdr2-saintdenis-thumb.jpeg', alt: 'RDR2 saint denis' },
            { src: '/img/gaming-art-og/rdr2-sunset-thumb.jpeg', alt: 'RDR2 sunset' },
            { src: '/img/gaming-art-og/rdr2-mountain-landscape-thumb.jpeg', alt: 'RDR2 landscape' },
            { src: '/img/gaming-art-og/rdr2-saintdenis-2-thumb.jpeg', alt: 'RDR2 saint denis' },
            { src: '/img/gaming-art-og/rdr2-town-thumb.jpeg', alt: 'RDR2 town' },
            { src: '/img/gaming-art-og/rdr2-train-thumb.jpeg', alt: 'RDR2 train' },
            { src: '/img/gaming-art-og/rdr2-horse-riding-thumb.jpeg', alt: 'RDR2 horse riding' },
            { src: '/img/gaming-art-og/rdr2-smokin-thumb.jpeg', alt: 'RDR2 smokin' },
            { src: '/img/gaming-art-og/mirrors-edge-2-thumb.jpeg', alt: 'Mirros Edge 2' },
            { src: '/img/gaming-art-og/ghost-of-tsushima-view-thumb.jpg', alt: 'Ghost of Tsushima Edge view' },
            { src: '/img/gaming-art-og/ghost-of-tsushima-mountain-thumb.jpg', alt: 'Ghost of Tsushima mountain' },
            { src: '/img/gaming-art-og/marvel-spiderman-ps4-building-stand-thumb.jpg', alt: 'SpiderMan ps4 Building' },
            { src: '/img/gaming-art-og/marvel-spiderman-ps4-doggo-thumb.jpg', alt: 'SpiderMan ps4 Doggo' },
            { src: '/img/gaming-art-og/rdr2-ps4-nap-thumb.jpg', alt: 'RDR2 ps4 nap ' },
            { src: '/img/gaming-art-og/rdr2-ps4-sunset-orange-thumb.jpg', alt: 'RDR2 ps4 sunset orange ' },
            { src: '/img/gaming-art-og/rdr2-ps4-house-thumb.jpg', alt: 'RDR2 ps4 House' },
            { src: '/img/gaming-art-og/rdr2-ps4-sunlight-thumb.jpg', alt: 'RDR2 ps4 sunlight' },
            { src: '/img/gaming-art-og/gow-dragon-thumb.jpg', alt: 'GOW Dragon' },
            { src: '/img/gaming-art-og/gow-mountain-frosty-thumb.jpg', alt: 'GOW Frosty mountain' },
            { src: '/img/gaming-art-og/gow-mountain-tree-thumb.jpg', alt: 'GOW Tree on mountain' },
            { src: '/img/gaming-art-og/gow-tree-thumb.jpg', alt: 'GOW Tree' },
            { src: '/img/gaming-art-og/gow-kratos-atreus-thumb.jpg', alt: 'GOW Kratos and Atreus' },
            { src: '/img/gaming-art-og/gow-world-surpent-thumb.jpg', alt: 'GOW World Surpent' },
        ],
        currentIndex: 0
      };
    },
    mounted() {
      this.loadImages()

      this.loadContent = false;
      this.loadHeading = false;
      this.loadImage = false;
      setTimeout(() => {
        this.loadContent = true;
      }, 1000);
      setTimeout(() => {
        this.loadHeading = true;
        this.loadImage = true;
      }, 1500);
    },
    beforeDestroy() {
      this.loadContent = false;
      this.loadHeading = false;
      this.loadImage = false;
    },
    methods: {
      showLightBox(index) {
        this.currentIndex = index;
        this.$refs.lightbox.openLightBox(index);
      },
      loadImages() {
        // const requireContext = require.context('@/assets/gaming-art', false, /\.(jpg|jpeg|png|gif|webp)$/);
        // console.log('requireContext', requireContext)

        // this.images = requireContext.keys().map(requireContext);
        // console.log('images', images)
      }
    }
  };
  </script>
  