<template>
  <div class="
      transform-gpu
      transition-all
      duration-1000
      ease-out
      items-stretch
      justify-center
      h-screen
      max-w-100vw
      w-full
      overflow-hidden
      flex flex-col flex-1
      bg-white
    ">
    <div class="
        flex flex-col
        items-stretch
        text-left
        pl-8
        sm:pl-12
        md:pl-24
        xl:pl-32
        pt-20
        lg:pt-24
        flex-1
        relative
      ">
      <transition enter-active-class="tranform transition-all duration-500 ease-in"
        leave-active-class="tranform transition-all duration-500 ease-in" enter-class="opacity-0"
        enter-to-class="opacity-100" leave-class="opacity-100" leave-to-class="opacity-0">
        <HeaderComponent v-if="loadHeading" :darkTheme="true"></HeaderComponent>
      </transition>

      <transition enter-active-class="transform-gpu transition-all duration-500 ease-in-out"
        leave-active-class="transform-gpu transition-all duration-500 ease-in-out" enter-class="opacity-0"
        enter-to-class="opacity-100" leave-class="opacity-100" leave-to-class="opacity-0">
        <h1 v-show="loadContent" class="text-2xl max-w-3/4 md:text-4xl ml-1 font-headline">
          Cookies 4 Later
        </h1>
      </transition>
      <transition enter-active-class="transform transition-all duration-500 ease-in-out"
        leave-active-class="transform transition-all duration-500 ease-in-out" enter-class=""
        enter-to-class="opacity-100" leave-class="opacity-100" leave-to-class="opacity-0">
        <div v-show="loadContent" class="lg:pr-60" style="padding-bottom: 20px; max-height: 80vh; overflow: auto;">
          <div class="
              font-primary font-medium
              max-w-3/4
              lg:max-w-screen-lg
              text-md
              md:text-md
              leading-relaxed
              mt-5
              md:mt-6
              xl:ml-2">
            <div class="flex-1 flex justify-between flex-col text-md">
              <p class="mb-3">Welcome to my little corner of the internet! 🎮 I'm a gamer and software developer from
                the vibrant and diverse landscapes of South Africa.</p>
              <p>With thousands of hours across hundreds of games, I dive into everything—from intense strategy
                challenges to fast-paced action-adventure thrills 🛹. Whether it’s indie gems or blockbuster AAA titles,
                I love pushing the boundaries of play, causing a little chaos, and discovering new ways to experience
                each game. Beyond just playing, I have a passion for capturing the beauty of virtual worlds—snapping
                stunning in-game screenshots of landscapes, cities, characters, and even the occasional adorable in-game
                critter. 📸
              </p>
              <br />
              <p class="mb-3">When I’m not gaming, I channel my creativity into building websites and web apps, blending
                design with functionality to craft engaging digital experiences. My focus on user experience and
                attention to detail help bring ideas to life in unique and innovative ways.</p>
              <br />

              <section class="my-2">
                <h5 class="uppercase text-lg font-bold mb-3 underline">Gaming Art</h5>
                <p>Discover the art of virtual photography through the immersive world of video games.</p>
                <p>From breathtaking landscapes to intricate character portraits, this collection highlights the diverse
                  beauty found in virtual worlds.</p>

                <div class="mt-3 flex gap-4 align-center justify-start">
                  <router-link to="gaming-art" class="cursor-pointer w-auto text-sm md:text-base underline">View
                    Gallery</router-link>
                </div>
              </section>

              <br />

              <section class="my-2">
                <h5 class="uppercase radius-2 text-lg font-bold mb-3 underline">Projects</h5>

                <p class="mb-2">
                  <router-link to="kings"><img class="h-auto w-full sm:h-8 sm:w-8 mr-2 block sm:inline"
                      src="/img/kings-icon.png" alt="kings logo" /><span
                      class="inline cursor-pointer w-auto text-sm md:text-base underline"><strong>Kings</strong></span></router-link>:
                  A virtual card game where the user performs actions based on the card drawn from the circle. <a
                    class="ml-2 mr-1" href="https://play.google.com/store/apps/details?id=com.cookies4later.kings.twa"
                    title="Download on Google Play Store"><i
                      class="icon-googleplay text-black hover:text-yellow-600"></i></a>
                </p>
                <p class="mb-2">
                  <a target="_parent" href="https://domekeeper-memorygame.cookies4later.com"><img
                      class="h-auto w-full sm:h-8 sm:w-8 mr-2 block sm:inline " src="/img/heart-drillbert.jpg"
                      alt="kings logo" /><span
                      class="inline cursor-pointer w-auto text-sm md:text-base underline">DomeKeeper Memory
                      Game</span></a>: A mini memory card game themed to the style of the game Dome Keeper.
                </p>

              </section>

              <!-- <section class="my-2">
                <h5 class="uppercase radius-2 text-lg font-bold mb-3 underline">Websites</h5>

                <p class="mb-2">
                  <router-link to="/https://jamys.co.za" ><img class="h-auto w-full sm:h-8 sm:w-8 mr-2 block sm:inline" src="/img/kings-icon.png"/><span class="inline cursor-pointer w-auto text-sm md:text-base underline"><strong>Jamys</strong></span></router-link>: An elegant vegan dessertry based in Cape Town, South Africa. 
                </p>
                
              </section> -->
            </div>
          </div>
        </div>
      </transition>

      <router-link to="kings" class="
          h-screen
          fixed
          right-0
          top-0
          w-8
          md:w-12
          bg-darkblue
          text-white
          hover:bg-black
          transition transition-bg
          flex
          items-center
          justify-center
          z-10
        ">
        <div class="relative h-screen w-8 flex items-center justify-center">
          <span class="
              absolute
              uppercase
              w-100vh
              h-8
              flex
              text-sm
              md:text-base
              items-center
              justify-center
              transform
              rotate-90
              tracking-wide
            ">Kings - drinking game</span><i class="icon-arrow-down"></i>
        </div>
      </router-link>

      <router-link to="gaming-art" class="
          h-screen
          absolute
          right-8
          md:right-12
          top-0
          w-8
          md:w-12
          bg-darkblue
          text-white
          hover:bg-black
          transition transition-bg
          flex
          items-center
          justify-center
          z-10
        ">
        <div class="relative h-screen w-8 flex items-center justify-center">
          <span class="
              absolute
              uppercase
              w-100vh
              h-8
              flex
              items-center
              justify-center
              transform
              rotate-90
              tracking-wide
              text-sm
              md:text-base
            ">Gaming Art</span><i class="icon-arrow-down"></i>
        </div>
      </router-link>
    </div>

    <cookie-consent class="mb-8 ml-8 md:ml-0 md:mb-0 p-3 h-auto max-w-2/3 md:max-w-full md:w-full">
      <template slot="message" class="">
        This website uses cookies to ensure you get the best experience.
        <a class="underline ml-2" href="/terms">Read more</a>
      </template>
      <template slot="button">
        <button class="py-2 px-5 min-w-32 ml-4 bg-darkblue text-white ">Accept</button>
      </template>
    </cookie-consent>

  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import CookieConsent from 'vue-cookieconsent-component'

export default {
  name: "Home",
  components: {
    HeaderComponent,
    CookieConsent
  },
  data() {
    return {
      loadContent: Boolean,
      loadHeading: Boolean,
    };
  },
  mounted() {
    this.loadContent = true;
    this.loadHeading = true;
    // setTimeout(() => {
    //   this.loadContent = true;
    // }, 1500);
    // setTimeout(() => {
    //   this.loadHeading = true;
    // }, 300);
  },
  beforeDestroy() {
    this.loadContent = false;
    this.loadHeading = false;
  },
};
</script>
